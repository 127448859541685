<template>
  <v-dialog v-model="show" width="410" :dark="$dark.get()">
    <v-form>
      <v-card class="text-center">
        <v-card-title>
          Произшла ошибка или данные еще не добавлены.
        </v-card-title>
        <v-card-text style="font-size: 18px; line-height: 100%"
          >Попробуйте позже.</v-card-text
        >
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      show: true,
    };
  },
};
</script>
